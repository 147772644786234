.testimonyCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.5em;
  margin-top: 1.5em;
  background-color: white;
  border-top-left-radius: 50% 25%;
  border-top-right-radius: 1em 3em;
  border-bottom-right-radius: 50% 25%;
  border-bottom-left-radius: 1em 3em;
}

.testimonyWrapper{
  position: relative;
  display: flex;
  flex-direction: column;
}

.textContainer {
  text-align: center;
  color: #5a1d3E;
}

.firstName {
  font-weight: 600;
}

.bubble {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 70px;
  height: 60px;
  top: 0;
  
  background-color: rgba(134, 229, 180, 0.5);
  border-radius: 52% 48% 53% 47% / 53% 20% 80% 47%; 
}

.testimonyWrapper {
  height: 10%;
}


.quoteLogo {
  width: 70%;;
  height: 70%;
  z-index: 2000;
}

.content {
  font-size: 0.8rem;
  margin-top: 0.5em;
}

.ratingContainer {
  font-size: 1.5rem;
  color: #86e5b4;
}