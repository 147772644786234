.card {
  width: fit-content;
  height: fit-content;
  background-color: #F6EDE8;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 25px 25px;
  gap: 20px;
  border: none;
}

.socialContainer {
  width: 32px;
  height: 32px;
  border-radius: 10px;
  background-color: #5A1D3E;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition-duration: .3s;
}

.containerOne:hover {
  background-image: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  transition-duration: .3s;
}

.containerTwo:hover {
  background-color: #3b5998;
  transition-duration: .3s;
}

.containerThree:hover {
  background-color: #0072b1;
  transition-duration: .3s;
}

.containerFour:hover {
  background-color: #128C7E;
  transition-duration: .3s;
}

.socialContainer:active {
  transform: scale(0.9);
  transition-duration: .3s;
}

.socialSvg {
  width: 17px;
}

.socialSvg path {
  fill: rgb(255, 255, 255);
}

.socialContainer:hover .socialSvg {
  animation: slide-in-top 0.3s both;
}

@keyframes slide-in-top {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}


@media screen and (max-width: 991px) and (min-width: 768px) {

  .socialContainer {
    width: 50px;
    height: 50px;
  }

  .socialSvg {
    width: 25px;
  }

}

@media (min-width: 768px) and (max-width: 1366px) and (orientation: landscape) {
  .socialContainer {
    width: 50px;
    height: 50px;
  }

  .socialSvg {
    width: 25px;
  }
}
