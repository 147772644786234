.purpleBtn, .whiteBtn {
  width: 120px;
  height: 40px;
  font-family: "Montserrat", sans-serif;
  font-size: 17px;
  text-decoration: none;
  letter-spacing: 2px;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  position: relative;
  display: inline-block;
  margin: 20px;
  outline: none;
  text-align: center;
  z-index: 2;
  transition: all 0.3s ease;
  overflow: hidden;
  font-weight: 600;
}

.purpleBtn {
  border: 2px solid #5A1D3E;
  color: #5A1D3E;    
}

.whiteBtn {
  border: 2px solid white;
  color: white;
}

.purpleBtn::after, .whiteBtn::after {
  position: absolute;
  content: " ";
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;
}

.purpleBtn:hover, .whiteBtn:hover {
  box-shadow: 4px 4px 6px 0 rgba(255,255,255,.5),
              -4px -4px 6px 0 rgba(116, 125, 136, .2), 
    inset -4px -4px 6px 0 rgba(255,255,255,.5),
    inset 4px 4px 6px 0 rgba(116, 125, 136, .3);
}

.purpleBtn:hover {
  color: #5A1D3E;
}


.purpleBtn:hover::after, .whiteBtn:hover::after {
  transform: scale(2) rotate(180deg);
  
  box-shadow: 4px 4px 6px 0 rgba(255,255,255,.5),
              -4px -4px 6px 0 rgba(116, 125, 136, .2), 
    inset -4px -4px 6px 0 rgba(255,255,255,.5),
    inset 4px 4px 6px 0 rgba(116, 125, 136, .3);
}


.purpleBtn:hover::after {
  background: white;
  color: #5A1D3E;
}

.whiteBtn:hover::after {
  background: #5A1D3E;  
}