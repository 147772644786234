@import url('https://fonts.googleapis.com/css2?family=Averia+Libre:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Chivo:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

body {
  background-color: #F6EDE8;
}

.titleWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}

.homMain {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.homeTitle {
  font-family: "Chivo", sans-serif;
  font-size: 1.5rem;
  margin: 0;
  color: #5A1D3E;
}

.titleSlogan {
  font-family: "Averia Libre", system-ui;
  font-optical-sizing: auto;
  color: #5A1D3E; 
}

section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.topLogo, .bodyLogo, .footerLogo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-items: center;
  width: 95vw;
  border: 1px dotted rgba(90, 29, 62, 0.5);
  color: #5A1D3E;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  background: rgba(255, 255, 255, 0.5);
  box-sizing: content-box;
}

.spanTitle {
  font-weight: bold;
  width: 100%;
  text-align: center;
  
}

.pourText {
  text-align: center;
  font-size: 0.8rem;
}

.spaceLogo {
  background-color: #F6EDE8;
  height: 0.5em;
  z-index: 1000;
}

.logoWrapper {
  width: 95vw;
  margin: auto;
  background-image: url('../../../../src//assets//img/translogo.webp');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.ouItem {
  font-size: 0.8rem;
}

.commentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80vw;
  margin-top: 1em;
  color: #5A1D3E;
}

.comment {
  text-align: justify;
  font-size: 0.9rem;
  font-weight: 400;
}

.secondSection {
  margin-top: 1em;
  width: 95vw;
}

.citationWrapper {
  color: #5A1D3E;
}

.citation {
  font-size: 1rem;
  font-family: "Averia Libre", system-ui;
  font-weight: 600;
  margin: 0;
  text-align: center;
}

.socrate {
  text-align: end;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
}

.citationFooter {
  width: 85vw;
  font-size: 0.8rem;
  text-align: center;
  color: #5A1D3E;
  font-family: "Open Sans", sans-serif;
  font-weight: 00;
  margin-top: 0.5em;
}

h2 {
  padding: 0.5em;
  font-family: "Averia Libre", system-ui;
  color: #5A1D3E;
  font-size: 1.2rem;
  text-align: center;
}

.infoCardWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  margin-top: 2em
}

.infoConclusion {
  margin-top: 1.7em;
}

.infoText {
  font-size: 0.8rem;
  color: #5A1D3E;
  font-family: "Open Sans", sans-serif;
  padding: 1em;
  margin: 0;
}

.thirdIntro {
  color: #5A1D3E;
  text-align: center;
  font-size: 0.8rem;;
}

/*Photo marine*/
.portrait {
    border-radius: 50%;
    border: 2px solid green;
    width: 10rem;
}

/*Réseau sociaux*/
.socialWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/*Image main et plante*/
.decoImg {
  width: 100vw;
}

/*Button pour rdv psychologue.net*/
.buttonPsycho {
  padding: 0.5em;
  border: .2rem solid #5A1D3E;
  border-radius: 95% 4% 97% 5% / 4% 94% 3% 95%;
  color: #5A1D3E;
  background: none;
  cursor: pointer;
  margin-top: 1em;
  font-size: 0.9rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  transition: all .2s linear;
  width: 45%;
}

.buttonPsycho:hover {
  border-radius: 4% 95% 6% 95% / 95% 4% 92% 5%;
  border: .2rem solid #5A1D3E;
}

.fiveSection {
  width: 100vw;
}

.sliderTitle {
  margin-top: 1.5em;
  padding: 0.5em;
}

.testimonyBtn {
  margin-top: 3em;
}

.priceWrapper {
  position: relative;
  background: #F6EDE8;
  border: 1px solid transparent;
  width: 90vw;
  margin-top:2.5em;
  padding: 0.6em;
  border-radius: 10px;
  text-align: center;
  color: #5A1D3E;
}

.priceWrapper::before {
  content: '';
    position: absolute;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    background: #5A1D3E;
    z-index: -1;
    transform: skew(2deg,1deg);
    border-radius: 10px;
}

.priceItem {
  font-family: "Open Sans", sans-serif;
  font-size: 0.8rem;
}

.asterixInfo {
  font-family: "Open Sans", sans-serif;
  font-size: 0.6rem;
  color: #5A1D3E;
  text-align: center;
  padding: 0.5em;
  margin: 0.5em;
}

.neovieWrapper {
  text-align: center;
  margin-top: 3em;
}
.logoNeovie {
  width: 50vw;
  cursor: pointer;
  margin-top:1em;
}

.neovieTitle {
  color: #5A1D3E;
  font-family: "Averia Libre", system-ui;
}

.impotWrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1em;
  
  width: 95vw;
}
.impotCheque {
  width: 20%;
  height: 20%;
 
}

.impotText {
  font-family: "Open Sans", sans-serif;
  font-size: 0.6rem;
  color: #5A1D3E;
  padding: em;
  text-align: center;
  margin: auto;
}

.sixSection {
  margin-top: 2em;
}

.ethiqueImg {
  width: 100vw;
}




@media screen and (min-width: 2559px) {
 

}

/* Plus grands écrans */
@media screen and (min-width: 1400px) {

}

/* Grands écrans de bureau */
@media screen and (max-width: 1399px) and (min-width: 1201px) {
 
}

/* Tablettes et petits écrans de bureau */
@media screen and (max-width: 1200px) and (min-width: 992px) {
 
}

/* Tablettes en orientation portrait et grands mobiles */
@media screen and (max-width: 991px) and (min-width: 768px) {

  .homeTitle {
    font-size: 1.8rem;
  }

  .titleSlogan{
    font-size: 1.3rem;
  }

  .firstSection {
    margin-top: 3em;
  }

  .logoWrapper, .topLogo, .bodyLogo, .footerLogo {
    width: 80vw;
  
  }

  .backgroundLogo {
    width: 100%;
  }

  .spanTitle {
    font-size: 1.2rem;
  }

  .pourText {
    font-size: 1rem;
    margin-top: 0.7em;
  }

  .ouItem {
    font-size: 1rem;
  }

  .comment {
    font-size: 1rem;
  }

  .secondSection {
    margin-top: 3em;
  }

  .citation {
    font-size: 1.2rem;
  }

  .socrate {
    font-size: 0.9rem;
  }

  .citationFooter {
    font-size: 0.9rem;
  }

  .h2SecondSection {
    width: 100%;
    margin-top: 2em;
    font-size: 1.2rem;
  }

  .infoCardWrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
  }

  .infoText {
    font-size: 1rem;
    padding: 1em;
  }

  .thirdSection {
    margin-top: 3em;
  }

  .h2ThirdSection {
    font-size: 1.5rem;
  }

  .thirdIntro {
    font-size: 1rem;
    padding: 1.2em;
  }

  .portrait {
    width: 13rem;
  }

  .fourSection {
    margin-top: 3em;
  }

  .h2FourSection {
    font-size: 1.5rem;;
  }

  .buttonPsycho {
    width: 25%;
  }

  .priceWrapper {
    width: 50%;
  }

  .asterixInfo {
    font-size: 0.8rem;
  }

  .impotCheque {
    width: 12%;
  }

  .impotText {
    font-size: 0.8rem;
  }

  
}

@media (min-width: 768px) and (max-width: 1366px) and (orientation: landscape) {

  .homeTitle {
    font-size: 1.8rem;
  }

  .titleSlogan{
    font-size: 1.3rem;
  }

  .firstSection {
    margin-top: 3em;
  }

  .logoWrapper, .topLogo, .bodyLogo, .footerLogo {
    width: 80vw;
  
  }

  .backgroundLogo {
    width: 100%;
  }

  .spanTitle {
    font-size: 1.2rem;
  }

  .pourText {
    font-size: 1rem;
    margin-top: 0.7em;
  }

  .ouItem {
    font-size: 1rem;
  }

  .comment {
    font-size: 1rem;
  }

  .secondSection {
    margin-top: 3em;
  }

  .citation {
    font-size: 1.2rem;
  }

  .socrate {
    font-size: 0.9rem;
  }

  .citationFooter {
    font-size: 0.9rem;
  }

  .h2SecondSection {
    width: 100%;
    margin-top: 2em;
    font-size: 1.2rem;
  }

  .infoCardWrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 100px;
  }

  .infoText {
    font-size: 1rem;
    padding: 1em;
  }

  .thirdSection {
    margin-top: 3em;
  }

  .h2ThirdSection {
    font-size: 1.5rem;
  }

  .thirdIntro {
    font-size: 1rem;
    padding: 1.2em;
  }

  .portrait {
    width: 13rem;
  }

  .fourSection {
    margin-top: 3em;
  }

  .h2FourSection {
    font-size: 1.5rem;;
  }

  .buttonPsycho {
    width: 25%;
  }

  .priceWrapper {
    width: 50%;
  }

  .asterixInfo {
    font-size: 0.8rem;
  }

  .impotCheque {
    width: 12%;
  }

  .impotText {
    font-size: 0.8rem;
  }

  
}





