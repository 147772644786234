/* modal.css */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3000; 
 
}

.modalContainer {
  background: transparent;
  padding: 20px;
  border-radius: 5px;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto; 
  background-color: #F6EDE8;
}

.closeModal {
  position: absolute;
  top: 10px;
  right: 10px; 
  width: 30px;
  height: 30px; 
  border-radius: 50%; 
  border: none; 
  background-color: transparent;
  color: #5a1d3e; 
  font-size: 20px; 
  cursor: pointer; 
  align-items: center;
  justify-content: center;
  z-index: 3000;
  padding: 0; 
}


.closeModal:focus {
  outline: none;
}

@media screen and (max-width: 991px) and (min-width: 768px) {

  .modalContainer {
    width: 500px;
  }
}

@media (min-width: 768px) and (max-width: 1366px) and (orientation: landscape) {

  .modalContainer {
    width: 500px;
  }
}