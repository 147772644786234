@import url('https://fonts.googleapis.com/css2?family=Alice&family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

header{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;  
  background-image: url('../../../../src//assets//img//header_mobile.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;  
}

.topHeader {
  z-index: 1000; 
  right: 0;
  height: 100vh;
  width: 100%;
  color: white;
  margin-top: 5em;
  text-align: center; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

.headerName {
  font-size: 2rem; 
  font-family: "Alice", serif;
  font-weight: 600;
  margin:0;
  color: #5A1D3E;
}


.headerSlogan {
  font-size: 1.3rem;
  font-family: "Alice", serif;
  color: #5A1D3E;
  font-weight: 600;
}

.bottomHeader{
  text-align: center;
  width: 100%;
  margin-bottom: 2em;
}



@media screen and (min-width: 2559px) {
 

}

/* Plus grands écrans */
@media screen and (min-width: 1400px) {

}

/* Grands écrans de bureau */
@media screen and (max-width: 1399px) and (min-width: 1201px) {
 
}

/* Tablettes et petits écrans de bureau */
@media screen and (max-width: 1200px) and (min-width: 992px) {
 
}

/* Tablettes en orientation portrait et grands mobiles */
@media screen and (max-width: 991px) and (min-width: 768px) {

  header{  
    background-image: url('../../../../src//assets//img//header_grand.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;  
  }

  .headerName {
    font-size: 2.5rem; 
   
  }

  .headerSlogan {
    font-size: 1.8rem;  
  }

}


@media (min-width: 768px) and (max-width: 1366px) and (orientation: landscape) {

  header{  
    background-image: url('../../../../src//assets//img//header_grand.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;  
  }
 
}
