@import url('https://fonts.googleapis.com/css2?family=Averia+Libre:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Chivo:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

.infoCard {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.infoImg {
  width: 120px;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.8rem;
  color: #5A1D3E;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
}

.infoText {
  text-align: center;
}

@media (min-width: 768px) and (max-width: 1366px) and (orientation: landscape) {
  .infoImg {
    width: 150px;
  }

  .textWrapper {
    font-size: 0.8rem;
    height: 48px;
    display: flex;
    align-items: center;
  }

}