.logoDiplomeWrapper {
  width: 100%;
}

.logoLine1, .logoLine2 {
 text-align: center;
    
}

.logo1, .logo2, .logo3, .logo4 {
  width: 30vw;
  margin: 1em;
}
