
.servicesWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}


@media screen and (max-width: 991px) and (min-width: 768px) {

  .servicesWrapper {
    flex-wrap: nowrap;
    width: 100vw;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .serviceImg{
    width: 150px;
  }

  .serviceName {
    font-size: 0.8rem;
  }


}


@media (min-width: 768px) and (max-width: 1366px) and (orientation: landscape) {
  .servicesWrapper {
    flex-wrap: nowrap;
    justify-content: space-evenly;
   
  }
}