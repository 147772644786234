.servicesCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 45vw;

}

.serviceImg {
  width: 100px;
}

.serviceName {
  width: 90%;
  font-size: 0.7rem;
  color: #5A1D3E;
  font-weight: 700;
  text-align: center;
  font-family: "Open Sans", sans-serif;
}


@media (min-width: 768px) and (max-width: 1366px) and (orientation: landscape) {

  .serviceImg {
    width:
     150px;
  }

  .serviceName {
    font-size: 0.8rem;
  }

  .servicesCard {
    width: 33vw;
  
  }



}