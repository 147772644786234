@import url('https://fonts.googleapis.com/css2?family=Alice&family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.contactHeader {
  display: flex;
  flex-direction: column;
  color: white;
  font-family: "Alice", serif;
  font-size: 0.9em;
}

.phone {
  text-decoration: none;
  color: white;
  margin-bottom: 0.5em;
}


/* Tablettes en orientation portrait et grands mobiles */
@media screen and (max-width: 991px) and (min-width: 768px) {
  .contactHeader {
    font-size: 1rem;
  } 
}

@media (min-width: 768px) and (max-width: 1366px) and (orientation: landscape) {

}